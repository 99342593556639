import $ from "jquery";
require("packs/coupon_click");

// Check for user preference in localStorage on page load
document.addEventListener("DOMContentLoaded", () => {
    checkThemePreference();
    let body = document.querySelector("html");
    // let savedDarkMode = getCookie("darkMode");
    let themeToggle2 = document.getElementById("themeToggle-2");
    let footerButton = document.getElementById("footer-show");
    let footerContent = document.getElementById("footer-content");

    // if (savedDarkMode === "true") {
    //   body.classList.add("dark");
    // }

    // Optionally, update the checkbox state based on the current theme
    themeToggle.checked = body.classList.contains("dark");
    themeToggle2.checked = body.classList.contains("dark");

    footerButton.addEventListener("click", () => {
        toggleFooter(false);
    });

    let sideBarMenus = document.getElementById("side-bar-menu-open");
    let sideBarClose = document.getElementById("side-bar-menu-close");
    const swipeArea = document.getElementById("side-bar-menu");
    let startX, startY, endX, endY;
    let isSwiping = false;
    const MIN_SWIPE_DISTANCE = 50;

    swipeArea.addEventListener('touchstart', function (e) {
        const touch = e.touches[0];
        startX = touch.clientX;
        startY = touch.clientY;
        isSwiping = true;
    }, { passive: true });

    swipeArea.addEventListener('touchmove', function (e) {
        if (!isSwiping) return;
        const touch = e.touches[0];
        endX = touch.clientX;
        endY = touch.clientY;
    }, { passive: true });

    swipeArea.addEventListener('touchend', function (e) {
        if (!isSwiping) return;
        const diffX = endX - startX;
        const diffY = endY - startY;

        if (Math.abs(diffX) > Math.abs(diffY) && Math.abs(diffX) > MIN_SWIPE_DISTANCE) {
            if (diffX < -150) {
                // console.log('Swiped Left', diffX);
                closeSidePanel();
                isSwiping = false;
            }
        }
    }, { passive: true });

    $(".resend_link").click(function(){
        var login = $(this).data('login');
        var self = $(this);
        $.ajax({
            url: "/profile/resend_confirmation_email",
            type: "post",
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            data: {"login": login},
            success: function () {
                $("#resend_email_msg").html(I18n.t("confirmation_mail_sent"));
                self.hide();
            }
        });
    })

    sideBarMenus.addEventListener("click", openSidePanel);

    sideBarClose.addEventListener("click", closeSidePanel);

    function openSidePanel() {
        document.body.style.overflow = 'hidden';
        document.getElementById("side-bar-menu").classList.remove("hidden");
        setTimeout(() => {
            document.getElementById("side-bar-menu").classList.remove("-translate-x-full");
        }, 1);
    }

    function closeSidePanel() {
        isSwiping = false;
        document.body.style.overflow = 'scroll';
        document.getElementById("side-bar-menu").classList.add("-translate-x-full");
        setTimeout(() => {
            document.getElementById("side-bar-menu").classList.add("hidden");
        }, 100);
    }

    document.querySelectorAll(".menu-item").forEach((item) => {
        item.addEventListener("click", (event) => {
            let menuList = event.currentTarget.querySelector(".menu-list");
            let subMenuItem = event.currentTarget.querySelectorAll(".sub-menu-item");
            let arrowIcon = event.currentTarget.querySelector(".iconrot");

            // Check if the menu-list should toggle
            if (menuList && (event.target === menuList || !menuList.contains(event.target))) {
                menuList.classList.toggle("hidden");
                arrowIcon.classList.toggle("-rotate-90");
            } else if (subMenuItem.length === 0) {
                // If there's no subMenuItem, always toggle
                menuList.classList.toggle("hidden");
                arrowIcon.classList.toggle("-rotate-90");
            }
        });
    });

    document.querySelectorAll('.sub-menu-item').forEach((item) => {
        item.addEventListener("click", (event) => {
            let subMenuList = event.currentTarget.querySelector(".submenu-list");
            let iconrots = event.currentTarget.querySelectorAll('.iconrot');
            subMenuList.classList.toggle('hidden');
            iconrots.forEach(function (iconrot) {
                iconrot.classList.toggle('hidden');
            });

        });
    })

    // notification code start
    let notificatnicon = document.querySelector(".notificationIcon");
    let notificationdropdown = document.querySelector(".notificationdrop");

    notificatnicon.addEventListener("click", () => {
        notificationdropdown.classList.toggle("hidden");
    });

    document.addEventListener("click", function (event) {
        if (
            !notificatnicon.contains(event.target) &&
            !notificationdropdown.contains(event.target)
        ) {
            notificationdropdown.classList.add("hidden");
        }
    });
    // notification code END

    // Search
    let searchBox = document.getElementById("search-box");
    let searchBoxSuggest = document.getElementById("search-box-suggest");
    let clearSearch = document.querySelectorAll(".clear-search");
    let dealsSearch = document.getElementById("deals-search");
    let couponSearch = document.getElementById("stores-search");
    let discussionSearch = document.getElementById("discussions-search");

    let searchResultContainer = document.getElementById(
        "search-result-container"
    );
    let mostSearchedContainer = document.getElementById(
        "most-searched-container"
    );
    let noSearchesContainer = document.getElementById(
        "no-search-result-container"
    );

    searchBox.addEventListener("click", function (event) {
        searchBoxSuggest.style.display = "block";
    });

    let typingTimeout;
    searchBox.addEventListener("keyup", function (event) {
        if (searchBox.value.length >= 3 && (event.key === 'Enter' || event.keyCode === 13)) {
            if (dealsSearch.style.display != "none" && dealsSearch.innerHTML != "") {
                window.location.href = dealsSearch.querySelector("a").href;
            } else if (discussionSearch.style.display != "none" && discussionSearch.innerHTML != ""){
                window.location.href = discussionSearch.querySelector("a").href;
            } else if (couponSearch.style.display != "none" && couponSearch.innerHTML != ""){
                window.location.href = couponSearch.querySelector("a").href;
            }
        }
        clearTimeout(typingTimeout);
        if (searchBox.value.length >= 3) {
            $(".search-query").html("'" + searchBox.value + "'");
            $(".clear-search").removeClass("hidden");
            setClearEvent();
            typingTimeout = setTimeout(function () {
                getSearchData(searchBox.value);
            }, 300);
        } else if (searchBox.value.length === 0) {
            mostSearchedContainer.style.display = "block";
            searchResultContainer.style.display = "none";
            noSearchesContainer.style.display = "none";
            $(".clear-search").addClass("hidden");
        } else {
            mostSearchedContainer.style.display = "block";
            searchResultContainer.style.display = "none";
            noSearchesContainer.style.display = "none";
        }
    });

    function setClearEvent() {
        clearSearch.forEach(clear => {
            clear.addEventListener("click", function () {
                mostSearchedContainer.style.display = "block";
                searchResultContainer.style.display = "none";
                noSearchesContainer.style.display = "none";
                searchBoxSuggest.style.display = "block";
                this.classList.add("hidden");
                searchBox.value = "";
                searchBox.focus();
            })
        })
    }

    document.addEventListener("click", function (event) {
        let isClearSearch = false;
        clearSearch.forEach(function (clearElement) {
            if (event.target === clearElement || clearElement.contains(event.target)) {
                isClearSearch = true;
            }
        });
        if (event.target !== searchBox && event.target !== searchBoxSuggest && !isClearSearch) {
            searchBoxSuggest.style.display = "none";
        }
    });
    searchBox.addEventListener("click", function (event) {
        event.stopPropagation();
    });
    searchBoxSuggest.addEventListener("click", function (event) {
        event.stopPropagation();
    });
    // Search END

    // Scroll page to Top
    window.onscroll = () => {
        scrollFunction();
    };

    document.getElementById("scroll-top").onclick = function () {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    };

    $(document).on("click", ".submit_action", function () {
        var pathname = window.location.pathname;
        var type = $(this).data("type");
        submitPlus(pathname, type);
    });

    let commonOptions = {
        backdrop: "dynamic",
        backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
        closable: true,
        onHide: () => {
            let close = document.getElementById("closediv");
            let close_signup = document.getElementById("closedivsignup");
            if (close) {
                close.classList.add("hidden");
            }
            if (close_signup) {
                close_signup.classList.add("hidden");
            }
        },
        onShow: () => {
            let close = document.getElementById("closediv");
            let close_signup = document.getElementById("closedivsignup");
            if (close) {
                close.classList.remove("hidden");
            }
            if (close_signup) {
                close_signup.classList.remove("hidden");
            }
        },
        onToggle: () => {
        },
    };

    let loginModalTarget = document.getElementById("login-modal");
    let signupModalTarget = document.getElementById("signup-modal");
    let forgotPasswordTarget = document.getElementById("forgot-password-modal");

    // Login modal
    if (document.getElementById("loginmodal"))
        document.getElementById("loginmodal").addEventListener("click", openLogin);
    if (document.getElementById("closediv"))
        document.getElementById("closediv").addEventListener("click", closeLogin);
    if (document.getElementById("loginpop"))
        document.getElementById("loginpop").addEventListener("click", openLogin);

    // Signup modal
    if (document.getElementById("signupmodal"))
        document.getElementById("signupmodal").addEventListener("click", openSignup);
    if (document.getElementById("closedivsignup"))
        document.getElementById("closedivsignup").addEventListener("click", closeSignup);
    if (document.getElementById("loginpop"))
        document.getElementById("loginpop").addEventListener("click", openSignup);

    function createModal(target, options) {
        return new Modal(target, options, {
            id: target.id + "modal",
            override: true,
        });
    }

    document.querySelectorAll("#login_fm_id_modal, #password_fm_id_modal, #login_fm_id_page, #password_fm_id_page").forEach(function(element) {
        element.addEventListener("change", validateField);
        element.addEventListener("invalid", validateField);
    });

    function validateField(event) {
        var textfield = event.target;
        textfield.setCustomValidity('');
        if (!textfield.validity.valid) {
            textfield.setCustomValidity(I18n.t("mandatory_field"));
        }
    }

     //Password eye icon view JS

     let closePassEyes = document.querySelectorAll(".close-pass-eye");

     closePassEyes.forEach(function(closePassEye) {
         closePassEye.addEventListener("click", function() {
             let openPassEye = closePassEye.closest('.pass-container').querySelector(".open-pass-eye");
             openPassEye.classList.remove('hidden');
             closePassEye.classList.add('hidden');
             let passInput = document.getElementById(`password_fm_id_${closePassEye.dataset.context}`);
             if(passInput){
                passInput.type = 'text';
             }
             
             let signPassInput =  document.getElementById(`user_password_${closePassEye.dataset.context}`);
             if(signPassInput){
                signPassInput.type = 'text';
             }
           
             let signConfirmPassInput = document.getElementById(`user_password_confirmation_${closePassEye.dataset.context}`);
             if(signConfirmPassInput){
                signConfirmPassInput.type = 'text';
             }

             let resetPassInput  = document.getElementById(`user_password`);
             if(resetPassInput){
                resetPassInput.type = 'text';
             }

             let resetConfirmPassInput  = document.getElementById(`user_password_reset`);
             if(resetConfirmPassInput){
                resetConfirmPassInput.type = 'text';
             }
             
         });
     });
     
     let openPassEyes = document.querySelectorAll(".open-pass-eye");
     
     openPassEyes.forEach(function(openPassEye) {
         openPassEye.addEventListener("click", function() {
             let closePassEye = openPassEye.closest('.pass-container').querySelector(".close-pass-eye");
             openPassEye.classList.add('hidden');
             closePassEye.classList.remove('hidden');
             let passInput = document.getElementById(`password_fm_id_${openPassEye.dataset.context}`);
             if(passInput){
                passInput.type = 'password';
             }
             
             let signPassInput =  document.getElementById(`user_password_${closePassEye.dataset.context}`);
             if(signPassInput){
                signPassInput.type = 'password';
             }
         
             let signConfirmPassInput = document.getElementById(`user_password_confirmation_${closePassEye.dataset.context}`);
             if(signConfirmPassInput){
                signConfirmPassInput.type = 'password';
             }

             let resetPassInput  = document.getElementById(`user_password`);
             if(resetPassInput){
                resetPassInput.type = 'password';
             }

             let resetConfirmPassInput  = document.getElementById(`user_password_reset`);
             if(resetConfirmPassInput){
                resetConfirmPassInput.type = 'password';
             }
             
         });
     });
     
     


    window.createModal = createModal;

    // Common options for both login and signup modals

    function openLogin() {
        if (window.innerWidth < 768) {
            window.location.href = "/login";
            return;
        }
        createModal(signupModalTarget, commonOptions).hide();
        createModal(loginModalTarget, commonOptions).show();
    }

    function closeLogin() {
        createModal(loginModalTarget, commonOptions).hide();
    }

    function openSignup() {
        if (window.innerWidth < 768) {
            window.location.href = "/signup";
            return;
        }
        createModal(loginModalTarget, commonOptions).hide();
        createModal(signupModalTarget, commonOptions).show();
    }

    function closeSignup() {
        createModal(signupModalTarget, commonOptions).hide();
    }

    window.openLogin = openLogin;
    window.closeLogin = closeLogin;
    window.openSignup = openSignup;
    window.closeSignup = closeSignup;

    function forgotPasswordOpen() {
        createModal(loginModalTarget, commonOptions).hide();
        createModal(forgotPasswordTarget, commonOptions).show();
    }

    window.forgotPasswordOpen = forgotPasswordOpen;

    function getSearchData(query) {
        $.ajax({
            type: "GET",
            url: "/searches?keyword=" + query,
            dataType: "json",
            success: function (response) {
                if (
                    response.groups &&
                    response.groups.length == 0 &&
                    response.stores &&
                    response.stores.length == 0 &&
                    response.deals &&
                    response.deals.length == 0 &&
                    response.discussions &&
                    response.discussions.length == 0
                ) {
                    noSearchesContainer.style.display = "block";
                    searchResultContainer.style.display = "none";
                    mostSearchedContainer.style.display = "none";
                } else {
                    mostSearchedContainer.style.display = "none";
                    noSearchesContainer.style.display = "none";
                    searchResultContainer.style.display = "block";
                    populateSearchData(response);
                }
            },
        });
    }

    let emailInputForgotPass = document.getElementById('email-input');
    let submitBtn = document.getElementById('submit-btn');

    emailInputForgotPass?.addEventListener('input', function() {
        if (emailInputForgotPass.value.trim() !== '') {
        submitBtn.disabled = false;
        submitBtn.style.opacity = '1';
        submitBtn.classList.remove('cursor-not-allowed');
        submitBtn.classList.add('cursor-pointer');
        } else {
        submitBtn.disabled = true;
        submitBtn.style.opacity = '0.3';
        submitBtn.classList.add('cursor-not-allowed');
        }
    });

    // Signup Form validations
    let form = document.getElementById("email-form");
    let emailInput = document.getElementById("signup-email");
    let emailError = document.getElementById("email-error");

    // Function to validate email input
    function validateEmail() {
        if (!emailInput.checkValidity()) {
            // Show error message if the email is invalid
            emailError.classList.remove("hidden");
        } else {
            // Hide error message if the email is valid
            emailError.classList.add("hidden");
        }
    }

    // Attach event listeners to the email input
    if (emailInput) emailInput.addEventListener("input", validateEmail);
    if (emailInput) emailInput.addEventListener("blur", validateEmail);

    let usernameInput = document.getElementById("signup-username");
    let usernameError = document.getElementById("username-error");

    // Define validation rules for the username input
    function validateUsername() {
        // Check if the input is within the specified length range
        let minLength = 3;
        let maxLength = 16;
        let username = usernameInput.value;

        // Validation logic for username length
        if (username.length < minLength || username.length > maxLength) {
            // Show error message if the username is invalid
            usernameError.classList.remove("hidden");
        } else {
            // Hide error message if the username is valid
            usernameError.classList.add("hidden");
        }
    }

    // Attach event listeners to the username input
    if (usernameInput) usernameInput.addEventListener("input", validateUsername);
    if (usernameInput) usernameInput.addEventListener("blur", validateUsername);

    let passwordInput = document.getElementById("signup-password");
    let passwordError = document.getElementById("password-error");

    // Function to validate password input
    function validatePassword() {
        // Check if the password meets the required pattern
        if (!passwordInput.checkValidity()) {
            // Show error message if the password is invalid
            passwordError.classList.remove("hidden");
        } else {
            // Hide error message if the password is valid
            passwordError.classList.add("hidden");
        }
    }

    // Attach event listeners to the password input
    if (passwordInput) passwordInput.addEventListener("input", validatePassword);
    if (passwordInput) passwordInput.addEventListener("blur", validatePassword);

    // notification
    let current_user = document.querySelector("#current-user-login-status");
    if (current_user.dataset.ustatus == "false") {
        document.getElementById("notification_signup").addEventListener("click", openSignup);
        document.getElementById("view_all_signup").addEventListener("click", openSignup);

        function openSignup() {
            createModal(signupModalTarget, commonOptions).show();
        }
    }

    function fadeOut(element) {
        var opacity = 1;
        var fadeEffect = setInterval(function () {
            if (opacity <= 0.1) {
                clearInterval(fadeEffect);
                element.style.display = "none";
            } else {
                element.style.opacity = opacity;
                opacity -= 0.1;
            }
        }, 50);
    }

    function couponCodeCopy(){
        let couponCodes = document.querySelectorAll(".coupon-code-copy");
        couponCodes.forEach(coupon => {
            coupon.addEventListener("click", function () {
                let textToCopy = this.nextElementSibling.innerText;
                let copiedText = this.previousElementSibling;
                navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                        copiedText.style.display = "block";
                        copiedText.style.opacity = 1;
                        setTimeout(function () {
                            fadeOut(copiedText);
                        }, 2000);
                    })
                    .catch((err) => {
                        console.error("Unable to copy text: ", err);
                    });
            })
        }) 
    }
    window.couponCodeCopy = couponCodeCopy;

    couponCodeCopy();

    let dealPromoCardClose = document.querySelector(".close-best-deal-promo-card");
    if (dealPromoCardClose) {
        dealPromoCardClose.addEventListener("click", function () {
            this.parentNode.remove();
        })
    }

});

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
        if (cookie.indexOf(nameEQ) == 0) return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
}

function mark_read_or_unread_header(is_read, class_object, identifier_key) {
    $.ajax({
        method: "GET",
        url: '/mark_read_or_unread',
        data: {read: !is_read, identifier_key: identifier_key},
        dataType: "json",
        success: function (response) {
            var removeClass = is_read ? "not_read_link" : "read_link";
            var addClass = is_read ? "read_link" : "not_read_link";
            $(class_object).removeClass(removeClass).addClass(addClass);
            if (is_read) {
                $('#' + identifier_key).removeClass("read").addClass("bg-background-light dark:bg-background-btn-dark not_read");
                $('#' + identifier_key + '_header').removeClass("read").addClass("bg-background-light dark:bg-background-btn-dark not_read");
            } else {
                $('#' + identifier_key).removeClass("bg-background-light dark:bg-background-btn-dark not_read").addClass("read bg-light-theme-read dark:bg-dark-theme-read");
                $('#' + identifier_key + '_header').removeClass("bg-background-light dark:bg-background-btn-dark not_read").addClass("read bg-light-theme-read dark:bg-dark-theme-read");
            }

            if (response.unread_notification > 0) {
                $('#notification_red_circle').removeClass('hidden')
                $('#unread_notification_count').text(response.unread_notification)
            } else {
                $('#notification_red_circle').addClass('hidden')
            }

            class_object.removeAttribute('onclick');
            class_object.setAttribute("onclick", "mark_read_or_unread_header(" + !is_read + ", this, " + "'" + identifier_key + "'" + ")");
        },
        error: function (xhr, status, error) {
            call_toast();
        }
    });
}

function call_toast() {
    var partialContent = `<%= j(render partial: "components/notice_toast", locals: { message: "Something went wrong" }) %>`;
    document.body.insertAdjacentHTML('beforeend', partialContent);
    setTimeout(function () {
        var toast = document.getElementById('notice-toast');
        toast.classList.add('fade-out');
        setTimeout(function () {
            toast.remove();
        }, 1000)
    }, 3000);
}

window.call_toast = call_toast;
window.mark_read_or_unread_header = mark_read_or_unread_header;

function toggleTheme() {
    let body = document.querySelector("html");
    let themeContainer = document.getElementById("themeContainer");
    let themeToggle = document.getElementById("themeToggle");
    let themeToggle2 = document.getElementById("themeToggle-2");
    body.classList.toggle("dark");
    if (body.classList.contains("dark")) {
        setCookie("darkMode", "true", 1000);
    } else {
        setCookie("darkMode", "false", 1000);
    }
    themeToggle.checked = body.classList.contains("dark");
    themeToggle2.checked = body.classList.contains("dark");
}

function checkThemePreference() {
    let savedDarkMode = getCookie("darkMode");
    if ((window.matchMedia("(prefers-color-scheme: dark)").matches == true || savedDarkMode === "true") && savedDarkMode != "false") {
        if (savedDarkMode == undefined || savedDarkMode == null) {
            setCookie("darkMode", "true", 1000);
        }
        let body = document.querySelector("html");
        let themeToggle = document.getElementById("theme-toggle");
        let themeToggle2 = document.getElementById("theme-toggle-2");

        if (themeToggle && getComputedStyle(themeToggle).display === "none" && themeToggle2) {
            themeToggle2.checked = body.classList.contains("dark") ? "checked" : "";
        } else if (themeToggle) {
            if (savedDarkMode == null || savedDarkMode == undefined) {
                body.classList.toggle("dark");
            }
            themeToggle.checked = body.classList.contains("dark") ? "checked" : "";
        }
    }
}

function populateSearchData(response) {
    //<%# Populating Groups and stores with single dynamic method %>
    displayItems(response, "groups", "groups-search", "groups-search-container", "groups");
    displayItems(response, "stores", "stores-search", "stores-search-container", "coupons");

    //<%# Dynamic function for Populating Groups and stores %>
    function displayItems(response, category, elementId, containerId, urlPath) {
        if (response[category] && response[category].length > 0) {
            $("#" + elementId).show();
            $("#" + containerId).html("");
            var items = "";
            $.each(response[category], function (index, value) {
                let name = (category === 'stores') ? value.merchant_name : value.name;
                items +=
                    '<a href="/' +
                    urlPath +
                    "/" +
                    value.permalink +
                    '?ref=most_searched">\
                      <div class="flex justify-between items-center mb-3">\
                    <div class="flex items-center font-medium text-[15px]">\
                      <div class="w-[48px] h-[48px] border border-light-border dark:border-dark-border rounded-lg mr-2 flex justify-center items-center">\
                        <img src="' +
                    value.image +
                    '" class="w-full max-w-[42px] h-auto max-h-[42px]" alt="' +
                    category +
                    ' Image">\
                      </div>\
                      ' +
                        name
                         +
                    '\
                    </div>\
                    <div>\
                      <svg class="w-[24px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">\
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 16 4-4-4-4"/>\
                      </svg>\
                    </div>\
                  </div>\
                  </a>';
            });
            $("#" + containerId).html(items);
        } else {
            $("#" + elementId).hide();
        }
    }

    // <%# Populating Deals, Discussions and Coupons with single dynamic method %>
    displayCategory(response, "deals", "deals-search", I18n.t("deals"));
    displayCategory(response, "discussions", "discussions-search", I18n.t("discussions"));
    displayCategory(response, "coupons", "coupons-search", I18n.t("coupons"));

    // <%# Dynamic function for Populating Deals, Discussions and Coupons %>
    function displayCategory(response, category, elementId, countLabel) {
        let live_count = 0;
        let sort_type = "";
        if (category == 'deals') {
            live_count = response.live_deals_count
            sort_type = "&sort_type=new";
        }
        if (category == 'discussions') {
            live_count = response.live_discussions_count
            sort_type = "&sort_type=new";
        }
        if (category == 'coupons') {
            live_count = response.live_coupons_count
        }
        if (response[category] && live_count > 0) {
            $("#" + elementId).show();
            $("#" + elementId).html("");
            var categoryHTML =
                '<a href="/search_result?keyword=' + response.keyword + "&search_list=" + category + '&ref=most_searched' + sort_type + '">\
                      <div class="flex justify-between items-center mb-3">\
                    <div class="font-medium text-[15px]"> \'' +
                response.keyword +
                '\' <span class="font-bold text-primary">' +
                countLabel +
                "(" + live_count + ')</span></div>\
            <div>\
              <svg class="w-[24px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">\
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 16 4-4-4-4"/>\
              </svg>\
            </div>\
          </div>\
          </a>';
            $("#" + elementId).html(categoryHTML);
        } else {
            $("#" + elementId).hide();
            $("#" + elementId).html("");
        }
    }
}

function scrollFunction() {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
        document.getElementById("scroll-top").classList.remove("hidden");
        document.getElementById("footer-show").classList.remove("hidden");
        if (checkVisible("#footer-content")) {
            document.getElementById("footer-show").classList.add("hidden");
        }
    } else {
        document.getElementById("scroll-top").classList.add("hidden");
        document.getElementById("footer-show").classList.add("hidden");
    }

}

function checkVisible(elm, visibility = "visible") {
    let visibilityStatus = visibility;
    var vpH = $(window).height(), // Viewport Height
        st = $(window).scrollTop(), // Scroll Top
        y = $(elm).offset().top,
        elementHeight = $(elm).height();
    if (visibilityStatus == "visible") return ((y < (vpH + st)) && (y > (st - elementHeight)));
}

function submitPlus(path, type) {
    var url = "";
    if (type == "coupon") {
        url = "/coupons/new";
    } else if (type == "deal") {
        if ($("#forum-deal").length) {
            var deal = document.getElementById("forum-deal").value;
            url = "/deals/new?forum_id=" + deal;
        } else {
            url = "/deals/new";
        }
    } else if (type == "discussion") {
        if ($("#forum-discussion").length) {
            var discussion = document.getElementById("forum-discussion").value;
            url = "/discussions/new?forum_id=" + discussion;
        } else {
            url = "/discussions/new";
        }
    } else if (type == "feedback") {
        url = "/feedbacks/new";
    }
    if (url != path) {
        window.location =
            window.location.protocol + "//" + window.location.host + url;
    }
}

window.toggleTheme = toggleTheme;

function toggleFooter(isOpen) {
    let footerContent = document.getElementById("footer-content").innerHTML;
    document.getElementById("footer-drawer-content").innerHTML = footerContent;
    let footerDrawer = document.getElementById("footer-drawer");
    let target = footerDrawer;
    let options = {
        placement: 'bottom',
        backdrop: true,
        bodyScrolling: false,
        edge: false,
        edgeOffset: '',
        backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-30',
        onHide: () => {
            document.getElementById("footer-hide").classList.add("hidden");
            footerDrawer.style.transform = "translateY(100%)";
            setTimeout(() => {
                footerDrawer.style.display = "none";
                document.getElementById("footer-drawer-content").innerHTML = "";
            }, 200);
        },
        onShow: () => {
            document.getElementById("footer-hide").classList.remove("hidden");
            footerDrawer.style.display = "block";
            footerDrawer.style.transform = "translateY(0%)";

            document.getElementById("footer-hide").addEventListener("click", function () {
                toggleFooter(true);
            })
        },
        onToggle: () => {},
    }
    let instanceOptions = {
        id: 'footer-drawer',
        override: true
    };
    let instance = new Drawer(target, options, instanceOptions);
    if (isOpen) {
        instance.hide();
    } else {
        instance.show();
    }
}

function fadeOut(element) {
    var opacity = 1;
    var fadeEffect = setInterval(function () {
        if (opacity <= 0.1) {
            clearInterval(fadeEffect);
            element.style.display = "none";
            element.style.opacity = 1;
        } else {
            element.style.opacity = opacity;
            opacity -= 0.1;
        }
    }, 50);
}

function slideUpContent(element) {
    // Set the initial height and opacity
    element.style.height = element.scrollHeight + "px";
    element.style.opacity = 1;
    element.offsetHeight;
    element.style.transition = "height 0.5s ease, opacity 0.5s ease";
    element.style.height = "0px";
    element.style.opacity = 0;
    setTimeout(function () {
        element.classList.add('hidden');
        element.style.height = "";
        element.style.transition = "";
        element.style.opacity = "";
    }, 500);
}


function slideDownContent(element) {
    element.classList.remove('hidden');
    element.style.height = "0px";
    element.style.opacity = 0;
    element.offsetHeight;
    element.style.transition = "height 0.5s ease, opacity 0.5s ease";
    element.style.height = element.scrollHeight + "px";
    element.style.opacity = 1;
    setTimeout(function () {
        element.style.height = "";
        element.style.transition = "";
        element.style.opacity = "";
    }, 500);
}

function scrollToParentComment(str) {
    let element = document.getElementById(`post_${str}_new`)
    if (element) {
        element.scrollIntoView();
    }
}

function timerFunction(time_sec, element, callback){
    let timeLeft = time_sec;

    const timerInterval = setInterval(() => {
      const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft % 60;
  
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        
      if(element){
        element.textContent = formattedTime;
      }
  
      timeLeft--;
  
      // Stop the timer when time is up
      if (timeLeft < 0) {
        clearInterval(timerInterval);
        
        callback();
        
      }
    }, 1000);
}
window.timerFunction = timerFunction;
window.fadeOut = fadeOut;
window.slideUpContent = slideUpContent;
window.slideDownContent = slideDownContent;
window.scrollToParentComment = scrollToParentComment;